@tailwind base;
@tailwind components;
@tailwind utilities;


.main-image{
  /* position: relative; */
  background: url(banner.png) no-repeat center;
  background-size: cover;
  
  overflow: hidden;
}
.footer-image {
  position: relative;
  background: url(banner.png) no-repeat center;
  background-size: cover;
  
  overflow: hidden;
}

body::-webkit-scrollbar {
  display: none;
}

.pagination {
  display: flex;
}

.pagination li {
  margin: 0 5px;
  padding: 0 5px;
  border-radius: 20px;
}

.pagination li:hover {
  background-color: lightgray;
}

.pagination li.active {
  color: #00B061;
}

.required:after {
  content: " *";
  color: red;
}

.slick-list {
  overflow: visible;
}

.slick-next:before, .slick-prev:before {
  color: #00B061;
  font-size: 30px;
}

.filter-select {
  appearance: none;
  background-image: url(data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e);
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  background-size: 1em;
}

.article-section {
  min-height: calc(100vh - 90px);
  overflow: hidden;
}

.table-container {
  height: calc(100vh - 3rem);
}

.vantage-h1 {
  font-family: 'Roboto, -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif';
}

.vantage-height-1 {
  height: calc(100vh - 6rem);
}

@media (max-width: 767px) {
  .table-container {
    height: calc(100vh - 85px);
  } 
}